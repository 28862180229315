import { put, takeLeading, select, call } from 'redux-saga/effects';
import _ from 'lodash';
import Bugsnag from '@bugsnag/js';

import Notification from '../../../../../components/layout/Notification';
import { formatCartForApi } from '../../../../../util/Helpers';
import * as orderActions from '../../../reducers/ducks/legacy/OrderDuck';
import * as mainActions from '../../../reducers/ducks/legacy/MainDuck';
import {
  checkoutAdjust as restaurantApiCheckoutAdjust,
  checkoutEnd as restaurantApiCheckoutEnd,
  checkoutStart as restaurantApiCheckoutStart,
  getOrderCart,
  resetOrderCart,
  setOrderCart,
  getRestaurantDetails,
} from '../../../../../util/api/RestaurantApiClient';
import { getTimeParams } from '../../../../../util/Restaurant';
import errorMessages from '../../../../../constants/errorMessages';
import accountApi from '../../../../../util/api/AccountApiClient';
import { getAddressLocation } from '../../../../../util/api/MapApiClient';
import {
  setCartResponse,
  updateCartStatus,
} from '../../../reducers/ducks/legacy/OrderDuck';
import formatReorderCartForApi from '../../../../../util/cart';
import {
  getRestaurantDetailsRequest,
  getRestaurantMenuRequest,
  searchRestaurants,
  setSelectedRestaurant,
} from '../../../reducers/ducks/legacy/RestaurantsDuck';
import { getRestaurants } from '../../../../../util/api/ConsumerApiClient';
import { filterRestaurantsResponse } from './RestaurantsSaga';

/**
 * @returns {Array<{
 *   menuItemId: number;
 *   sort: number;
 *   quantity: number;
 *   comment: string;
 *   extras: {
 *     extraId: {
 *       id: number;
 *       items: Array<{
 *         id: number;
 *         quantity: number;
 *       }>;
 *     };
 *   };
 * }>}
 */
const formatCartForStore = (apiCart, menuItems) => {
  const cart = [];

  apiCart.forEach((cartItem) => {
    const menuItem = menuItems[cartItem.id];
    const items = cartItem.items.map((item) => {
      const extras = {};
      (item.answers || []).forEach((answer) => {
        const extraItem = {
          id: answer.answer,
          quantity: answer.amount || 1,
          extraId: null,
          parentId: null,
          sort: menuItem.options[answer.answer]?.sort,
        };

        if (answer.parent) {
          extraItem.id = answer.parent;
          extraItem.parentId = answer.answer;
          extraItem.sort = menuItem.options[answer.parent]?.sort;
        }

        const extra = menuItem.extras[menuItem.options[extraItem.id].parent];
        extraItem.extraId = extra.id;
        extraItem.order = extra.order;

        extras[extraItem.id] = extraItem;
      });

      return {
        menuItemId: cartItem.id,
        sort: item.sort,
        quantity: item.quantity || 1,
        comment: item.special_instructions || '',
        extras,
      };
    });

    cart.push(...items);
  });

  return cart.sort((a, b) => (a.sort > b.sort ? 1 : -1));
};

export function* checkoutAdjustSaga({ payload }) {
  const { selectedRestaurant, checkoutData, coupons } = yield select(
    ({ restaurants, order }) => ({
      selectedRestaurant: restaurants.selectedRestaurant,
      checkoutData: order.checkoutData,
      coupons: order.coupons,
    })
  );

  try {
    const requestBody = {
      client: selectedRestaurant,
      checkout: checkoutData?.id,
      data: payload,
    };

    const newCheckoutData = yield call(
      restaurantApiCheckoutAdjust,
      requestBody
    );

    yield put(
      orderActions.setCheckoutData({
        ...checkoutData,
        ...newCheckoutData,
      })
    );

    yield put(orderActions.setOrderFees(newCheckoutData.fees));
    if (!_.isEmpty(coupons[0])) {
      yield put(
        orderActions.setActiveCoupon({ isValid: true, coupon: coupons[0] })
      );
    }

    yield put(orderActions.setCheckoutAdjustResponse(newCheckoutData));
  } catch (err) {
    const errorMessage = err?.data?.message || err?.message;

    yield put(
      orderActions.setCheckoutAdjustResponse({
        error: true,
        errorMessage,
      })
    );

    Bugsnag.notify(err);

    Notification.error(errorMessage || 'Error while adjusting the checkout', {
      toastId: 'ErrorWhileAdjustingTheCheckout',
    });
  }
}

export function* checkoutStart() {
  try {
    const {
      selectedRestaurant,
      cartItems,
      type,
      time,
      address,
      phone,
      phones,
    } = yield select(
      ({
        order,
        restaurants,
        profile,
        address: selectedAddress,
        time: selectedTime,
      }) => ({
        selectedRestaurant: restaurants.selectedRestaurant,
        checkoutData: order.checkoutData,
        cartItems: order.cartItems,
        type: order.type,
        time: selectedTime.selectedTime,
        address: selectedAddress.selectedAddress,
        phone: order.phone,
        phones: profile.phones,
      })
    );

    const cart = formatCartForApi(cartItems);

    let phoneId;

    if (phone) {
      phoneId = phones?.find((i) => i.number === phone)?.id;
    } else {
      const primaryPhone = phones?.find((i) => i.primary);
      phoneId = primaryPhone?.id;
      yield put(orderActions.setPhone(primaryPhone?.number));
    }

    const requestBody = {
      client: selectedRestaurant,
      data: {
        mode: type.value,
        phone: phoneId,
        ...getTimeParams(time),
        cart,
      },
    };

    if (type.value === 'delivery' && address && address?.address_id) {
      requestBody.data.address = address.address_id;
    }

    const newCheckoutData = yield call(restaurantApiCheckoutStart, requestBody);
    newCheckoutData.restaurant = selectedRestaurant;
    newCheckoutData.noFees = {
      total: newCheckoutData.total,
      subtotal: newCheckoutData.subtotal,
    };

    const dynamicFeesValues = {};
    if (newCheckoutData?.fees) {
      newCheckoutData.fees.forEach((fee) => {
        if ((fee.adjustable || fee.adjustable_percentages) && fee.percent) {
          dynamicFeesValues[fee.id] = fee.percent;
        }
      });
    }

    yield put(orderActions.setDynamicFeesValues(dynamicFeesValues));

    yield put(orderActions.setCheckoutData(newCheckoutData));
    yield put(orderActions.setOrderFees(newCheckoutData.fees));
  } catch (err) {
    yield put(orderActions.setCheckoutData(null));

    Bugsnag.notify(err);

    Notification.error(
      err?.data?.message || err?.message || 'Error while starting the checkout',
      {
        toastId: 'ErrorWhileStartingTheCheckout',
      }
    );
  }
}

export function* checkoutEnd({ payload }) {
  try {
    const { selectedRestaurant, checkoutData } = yield select(
      ({ order, restaurants }) => ({
        checkoutData: order.checkoutData,
        selectedRestaurant: restaurants.selectedRestaurant,
      })
    );

    const requestBody = {
      client: selectedRestaurant,
      checkout: checkoutData.id,
      payment: payload.payment,
      data: payload.data,
    };

    yield call(restaurantApiCheckoutEnd, requestBody);
    yield call(resetOrderCart, selectedRestaurant);

    if (payload && payload?.callback) {
      payload.callback();
    }

    yield put(orderActions.setCheckoutData({ ...checkoutData, isEnded: true }));
  } catch (err) {
    Bugsnag.notify(err);

    Notification.error(
      err?.data?.message || err?.message || 'Error while ending the checkout',
      {
        toastId: 'ErrorWhileEndingTheCheckout',
      }
    );
  }
}

export function* placeOrder({ payload }) {
  try {
    yield put(
      mainActions.toggleGlobalLoaderQueue({
        name: 'checkout',
        status: true,
      })
    );
    yield put(
      orderActions.checkoutEnd({
        data: {},
        payment: 'cash',
      })
    );

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    payload.showError('Failed to place order.');
  }
}

export function* getExistingAddress(address) {
  try {
    const addresses = yield call(accountApi.address.getAddresses);

    yield put(
      orderActions.setExistingAddress(
        addresses.find(
          (item) =>
            item.address === address.payload.address &&
            item.state === address.payload.state &&
            item.zip === address.payload.zip
        )
      )
    );
  } catch (error) {
    orderActions.setExistingAddress(error);
  }
}

export function* getNewAddress(address) {
  try {
    const coordinates = yield call(getAddressLocation, address);
    yield put(
      orderActions.setNewAddress({
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        address: address.payload.address,
        city: address.payload.city,
        state: address.payload.state,
        zip: address.payload.zip,
        primary: address.payload.primary,
      })
    );
  } catch (error) {
    orderActions.setNewAddress(error);
  }
}

export function* createNewAddress(action) {
  try {
    const { zipcode, active, id, ...data } = action.payload.address;

    const newAddressResponse = yield call(accountApi.address.createAddress, {
      zip: zipcode,
      primary: false,
      ...data,
    });

    // Disabled it can be a different address, not the one currently used, the creator calling it should be the one to do the update
    /*
    const { userId } = action.payload;
    const updatedAddress = {
      entered_address: data.entered_address,
      address: data.address,
      city: data.city,
      state: data.state,
      zipcode,
      latitude: data.latitude,
      longitude: data.longitude,
      primary: false,
      user_id: userId,
      address_id: newAddressResponse.address.id,
    };

    yield put(addressActions.setSelectedAddress(updatedAddress));
    */

    yield put(orderActions.setAddressResponse(newAddressResponse));
  } catch (error) {
    yield put(orderActions.setAddressResponse(error));
  }
}

export function* getAddressResponse(newAddress) {
  try {
    yield put(
      orderActions.setAddressResponse(
        yield call(accountApi.address.createAddress, newAddress.payload)
      )
    );
  } catch (error) {
    yield put(orderActions.setAddressResponse(error));
  }
}

export function* setCart({ payload }) {
  const { user } = yield select(({ main }) => ({
    user: main.user,
  }));

  try {
    if (user && payload.isCheckoutStart) {
      const orderCart = yield call(setOrderCart, payload.apiData);
      yield put(updateCartStatus(true));
      yield put(setCartResponse({ response: orderCart }));
      if (payload?.adjust) {
        yield call(checkoutAdjustSaga, { payload: {} });
      }
    }
  } catch (err) {
    Bugsnag.notify(err);

    let errorMessage = err?.data?.message || err?.message;
    errorMessage = errorMessage?.includes('not found')
      ? errorMessages.ITEM_NOT_AVAILABLE
      : errorMessage;
    yield put(setCartResponse({ error: errorMessage }));
    Notification.error(errorMessage, {
      toastId: err?.data?.message,
    });
    yield put(updateCartStatus(false));
  } finally {
    yield put(updateCartStatus(false));
    if (payload.payloadCartData?.sort) {
      yield put(
        orderActions.removeUpdatingCartItems(payload.payloadCartData.sort)
      );
    }
  }
}

export function* getCart() {
  try {
    const { user, selectedRestaurant, cartRestaurant, menuItems } =
      yield select(({ main, restaurants, order }) => ({
        user: main.user,
        selectedRestaurant: restaurants.selectedRestaurant,
        cartRestaurant: order.cartRestaurant,
        menuItems: restaurants.menuItems,
      }));

    if (cartRestaurant !== selectedRestaurant) {
      yield put(orderActions.setDynamicFeesValues({}));
      yield call(setCart, { payloadCartData: { cart: [] } });
      return;
    }

    let cartFromApi = [];
    if (user) {
      const response = yield call(getOrderCart, selectedRestaurant);
      cartFromApi = formatCartForStore(response?.cart || [], menuItems);
    }

    yield put(orderActions.setCartItems(cartFromApi));
  } catch (err) {
    Bugsnag.notify(err);

    Notification.error(
      err?.data?.message || err?.message || 'Error while getting the cart',
      {
        toastId: 'ErrorWhileGettingTheCart',
      }
    );
  }
}

export function* createOptionalAddress({ payload }) {
  try {
    yield call(accountApi.address.updateAddress, payload.id, payload.data);
  } catch (error) {
    if (payload.showError) {
      payload.showError('Failed to update address.');
    }
  }
}

export function* checkReorderSchedule({ payload }) {
  const {
    callback: reorderCallback,
    time,
    missingItems,
    formDateValue,
  } = payload;

  const { order } = yield select((state) => state.order.reorder);
  const { address } = yield select((state) => state.address.selectedAddress);

  if (!order) {
    return;
  }

  try {
    const restaurantRequestParams = {
      type: 'asap',
      mode: order.type,
      ...(order.type !== 'delivery'
        ? {}
        : {
            lat: address && address?.latitude,
            lng: address && address?.longitude,
          }),
    };

    const restaurantsResponse = yield call(
      getRestaurants,
      restaurantRequestParams
    );

    if (restaurantsResponse === undefined || !restaurantsResponse) {
      throw new Error('restaurant not available');
    }

    const filteredRestaurants = filterRestaurantsResponse(
      restaurantsResponse,
      address,
      null,
      null
    );

    const orderRestaurant =
      filteredRestaurants.restaurants[order.restaurant.profile];

    if (!orderRestaurant) {
      throw new Error('restaurant not available');
    }

    if (!orderRestaurant.open) {
      // get available time slots
      const detailsResponse = yield call(
        getRestaurantDetails,
        orderRestaurant.profile
      );
      if (reorderCallback) {
        reorderCallback({
          type: 'time',
          details: detailsResponse,
        });
      }
    } else {
      yield put(
        orderActions.reorder({
          time,
          missingItems,
          formDateValue,
          callback: reorderCallback,
        })
      );
    }
  } catch (err) {
    if (reorderCallback) {
      reorderCallback({ type: 'restaurant' });
    }
  }
}

export function* reorder({ payload }) {
  const {
    callback,
    time: formTimeValue,
    missingItems,
    formDateValue,
  } = payload;

  const { order, restaurantsData, selectedAddress } = yield select(
    ({ order: orderData, restaurants, address: storedAddress }) => ({
      order: orderData.reorder.order,
      restaurantsData: restaurants.restaurants,
      selectedAddress: storedAddress.selectedAddress,
    })
  );

  if (!order) {
    return;
  }

  try {
    const cartData = formatReorderCartForApi(order.items, missingItems);

    const apiData = {
      client: order.restaurant.profile,
      data: {
        mode: order.type,
        cart: cartData,
        ...(selectedAddress ? { address: selectedAddress.address_id } : {}),
        ...getTimeParams(formTimeValue),
      },
    };

    yield call(setOrderCart, apiData);

    // set redux cart using the values from order history
    const {
      restaurant: { profile },
      type,
      address,
    } = order;
    // success - set redux for cart
    yield put(setSelectedRestaurant(profile));
    yield put(orderActions.setOrderDate(formDateValue));
    yield put(orderActions.setOrderTime(formTimeValue));
    const typeData = {
      label: type === 'delivery' ? 'Delivery' : 'Pickup',
      value: type,
      text: type === 'delivery' ? 'Delivery' : 'Pickup',
    };
    yield put(orderActions.setOrderType(typeData));
    let orderAddress = {};
    if (address) {
      orderAddress = {
        entered_address: `${address?.address}, ${address?.city}, ${address?.state} ${address?.zipcode}`,
        address: address?.address,
        city: address?.city,
        state: address?.state,
        zip: address?.zipcode,
        latitude: address?.latitude
          ? parseFloat(String(address.latitude))
          : undefined,
        longitude: address?.longitude
          ? parseFloat(String(address.longitude))
          : undefined,
      };
      yield put(orderActions.setOrderAddress(orderAddress));
    }

    yield put(getRestaurantMenuRequest({}));
    yield put(getRestaurantDetailsRequest(profile));

    if (!Object.keys(restaurantsData).length) {
      yield put(searchRestaurants());
    }

    if (callback) {
      callback();
    }
  } catch (err) {
    if (err?.data) {
      if (callback) {
        callback({ type: 'item', data: err?.data });
      }
    } else if (callback) {
      callback({ type: 'restaurant' });
    }
  }
}

export function* watchOrderSagas() {
  yield takeLeading(orderActions.getCart.type, getCart);
  yield takeLeading(orderActions.setCart.type, setCart);
  yield takeLeading(orderActions.checkoutStart.type, checkoutStart);
  yield takeLeading(
    orderActions.setCheckoutAdjustRequest.type,
    checkoutAdjustSaga
  );
  yield takeLeading(orderActions.checkoutEnd.type, checkoutEnd);
  yield takeLeading(orderActions.placeOrder.type, placeOrder);
  yield takeLeading(orderActions.getExistingAddress.type, getExistingAddress);
  yield takeLeading(orderActions.getNewAddressCoordinates.type, getNewAddress);
  yield takeLeading(orderActions.createNewAddress.type, createNewAddress);
  yield takeLeading(
    orderActions.getNewAddressResponse.type,
    getAddressResponse
  );
  yield takeLeading(
    orderActions.createOptionalAddress.type,
    createOptionalAddress
  );
  yield takeLeading(
    orderActions.checkReorderSchedule.type,
    checkReorderSchedule
  );
  yield takeLeading(orderActions.reorder.type, reorder);
}
