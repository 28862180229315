import React, { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/system';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import { Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { isEmpty } from 'lodash';
import { useUser } from '../../../hooks/useUser';

import {
  setCart,
  setDynamicFeesValues,
  setCoupons,
  setSelectedMenuItem,
  addUpdatingCartItems,
  setCartItems,
} from '../../../redux/legacy/reducers/ducks/legacy/OrderDuck';
import {
  toggleSignIn,
  toggleAddress,
} from '../../../redux/legacy/reducers/ducks/legacy/MainDuck';
import { getTimeParams, countMenuItemPrice } from '../../../util/Restaurant';
import useTotalCartPrice from '../../../util/hooks/UseCart';
import IncDecBtnGroup from '../IncDecBtnGroup';
import useClasses from '../../../hooks/legacy/useClasses';
import { formatCartForApi } from '../../../util/Helpers';
import useSetCart from '../../../hooks/legacy/useSetCart';

import cartImage from '../../../assets/images/emptyCartPlate.svg';

import styles from './CartFormComponent.style';

const TOTAL_COST_NAME = 'Total';

function CartFormComponent({
  isDesktop,
  showCheckoutButton,
  onCheckoutReady,
  isShowGiftCodeInput,
}) {
  const [couponString, setCouponString] = useState('');
  const classes = useClasses(styles);
  const dispatch = useDispatch();
  const location = useLocation();
  const { userData } = useUser();
  const [onCheckoutStart, setOnCheckoutStart] = useState(false);
  const [checkoutReady] = useSetCart(onCheckoutStart);

  const cartData = useTotalCartPrice();
  const { subtotalPrice } = cartData;
  let { feesArray } = cartData;
  const [isPurchaseEnabled, setIsPurchaseEnabled] = useState(false);

  const modalCartTotal = feesArray?.reduce((acc, fee) => {
    if (fee.id === 'cartSummaryLines') {
      // eslint-disable-next-line
      acc = fee.value.toFixed(2);
    }
    return acc;
  }, 0);

  const {
    cartItems,
    menuItems,
    orderType,
    updatingCartItems,
    dynamicFeesValues,
    minimums,
    coupons,
    isCheckout,
    selectedRestaurant,
    time,
    address,
    isCartModalOpen,
  } = useSelector(
    ({
      main,
      order,
      restaurant,
      address: selectedAddress,
      time: selectedTime,
    }) => ({
      cartItems: order.cartItems,
      menuItems: restaurant?.selectedRestaurantMenu?.menuItems,
      orderType: order?.type,
      updatingCartItems: order.updatingCartItems,
      dynamicFeesValues: order.dynamicFeesValues,
      minimums: order.minimums,
      coupons: order.coupons,
      checkoutData: order.checkoutData,
      address: selectedAddress.selectedAddress,
      time: selectedTime.selectedTime,
      selectedRestaurant: restaurant?.selectedRestaurantId,
      isCheckout: order.isCheckout,
      isCartModalOpen: main.isCartModalOpen,
    })
  );

  if (!cartItems.length) {
    feesArray = [];
  }

  const minOrderPrice = useMemo(
    () => minimums?.[orderType.value] || 0,
    [minimums, orderType]
  );
  const isMinOrderPrice = useMemo(
    () => minOrderPrice <= subtotalPrice,
    [minOrderPrice, subtotalPrice]
  );
  const isDelivery = useMemo(
    () => orderType?.value === 'delivery',
    [orderType]
  );

  useEffect(() => {
    const isPurchase =
      !updatingCartItems.length &&
      isMinOrderPrice &&
      cartItems.length &&
      Object.keys(menuItems || {}).length;
    setIsPurchaseEnabled(isPurchase);
  }, [updatingCartItems, isMinOrderPrice, cartItems, menuItems]);

  useEffect(() => {
    if (checkoutReady) {
      if (onCheckoutReady) {
        onCheckoutReady();
      }
    }
  }, [checkoutReady]);

  const deleteCartItem = useCallback(
    (index) => {
      const cartItem = cartItems[index];
      const newCart = [...cartItems];
      newCart.splice(index, 1);
      const newCartData = formatCartForApi(newCart);

      const apiData = {
        client: selectedRestaurant,
        data: {
          mode: orderType.value,
          ...getTimeParams(time),
          cart: newCartData,
          address: address?.id,
        },
      };
      dispatch(setCartItems(newCart));
      dispatch(addUpdatingCartItems(cartItem.sort));
      dispatch(
        setCart({
          payloadCartData: { cart: newCart, sort: cartItem.sort },
          apiData,
          userProfile: userData,
          pathname: location.pathname === '/checkout',
        })
      );
    },
    [
      cartItems,
      dispatch,
      selectedRestaurant,
      time,
      orderType,
      address,
      userData,
      location,
    ]
  );

  const editCartItem = useCallback(
    (index) => {
      const cartItem = cartItems[index];
      dispatch(
        setSelectedMenuItem({
          ...cartItem,
          id: cartItem.menuItemId,
        })
      );
    },
    [cartItems, dispatch]
  );

  const onQuantityChange = useCallback(
    (index, quantity) => {
      const newCart = [...cartItems];
      const cartItem = newCart[index];
      newCart[index] = {
        ...cartItem,
        quantity,
      };
      const newCartData = formatCartForApi(newCart);

      const apiData = {
        client: selectedRestaurant,
        data: {
          mode: orderType.value,
          ...getTimeParams(time),
          cart: newCartData,
          address: address?.id,
        },
      };
      dispatch(setCartItems(newCart));
      dispatch(addUpdatingCartItems(cartItem.sort));
      dispatch(
        setCart({
          payloadCartData: { cart: newCart, sort: cartItem.sort },
          apiData,
          userProfile: userData,
          pathname: location.pathname === '/checkout',
        })
      );
    },
    [
      cartItems,
      dispatch,
      selectedRestaurant,
      time,
      orderType,
      address,
      userData,
      location,
    ]
  );

  const onFeeValueChange = (name, value) => {
    const newDynamicFeesValues = { ...dynamicFeesValues };
    newDynamicFeesValues[name] = value;
    dispatch(setDynamicFeesValues(newDynamicFeesValues));
  };

  const onCheckoutStartClick = () => {
    if (isDelivery && !address) {
      dispatch(toggleAddress(true));
    } else if (userData) {
      setOnCheckoutStart(true);
    } else {
      dispatch(toggleSignIn(true));
    }
  };

  const applyCoupon = () => {
    if (!couponString) {
      return;
    }
    dispatch(setCoupons([...coupons, couponString]));
    setCouponString('');
    dispatch(setCartItems(cartItems));
    dispatch(setCart({ payloadCartData: { cart: cartItems } }));
  };

  const renderCartItems = () =>
    cartItems.map((cartItem, index) => {
      const menuItem = menuItems?.[cartItem.menuItemId];
      if (!menuItem) {
        return null;
      }
      const sortedAnswersInstructions = [...(cartItem?.answers ?? [])]
        .sort((a, b) =>
          a.order > b.order || (a.order === b.order && a.sort > b.sort) ? 1 : -1
        )
        .map((item) => item.specialInstructions);
      const itemPrice = countMenuItemPrice({
        menuItem,
        selectedOptions: cartItem.answers,
        orderType,
        quantity: cartItem.quantity,
      });
      const price = itemPrice.toFixed(2);
      const sortedAnswers = cartItem.answers?.map((item) =>
        `${item.quantity}x ${item?.text}`.replace(/^1x /, '')
      );
      const answersText = sortedAnswers.join(', ');
      const isUpdating = updatingCartItems.includes(cartItem.sort);

      return (
        <ListItem
          className={classes.listItem}
          key={`item-${cartItem.menuItemId}-${cartItem.sort}`}
          data-test-id="cart-item-wrapper"
        >
          <>
            <div className={classes.restName}>{menuItem.name}</div>

            <p className={classes.cartItems}>{answersText}</p>
            {sortedAnswersInstructions.join('').length > 0 && (
              <div className={classes.comment}>
                <ul>
                  <li className={classes.specialCommentText}>
                    {sortedAnswersInstructions.join(' ')}
                  </li>
                </ul>
              </div>
            )}
            {!isEmpty(cartItem.comment) && (
              <div className={classes.comment}>
                <span className={classes.commentText}>
                  Note: {cartItem.comment}
                </span>
              </div>
            )}
            <div className={classes.cartItemPanel}>
              <Stack direction="row" spacing={2}>
                <IconButton
                  size="small"
                  data-test-id="remove-item"
                  onClick={() => deleteCartItem(index)}
                  disabled={isUpdating}
                  className={classes.iconColor}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
                <IconButton
                  size="small"
                  data-test-id="edit-item"
                  onClick={() => editCartItem(index)}
                  disabled={isUpdating}
                  className={classes.iconColor}
                >
                  <FontAwesomeIcon icon={faPen} />
                </IconButton>
              </Stack>
              <div className={classes.incDecreaseCountPanel}>
                {isUpdating && <CircularProgress size={24} />}

                {!isUpdating && (
                  <IncDecBtnGroup
                    min={menuItems?.[cartItem.menuItemId]?.min || 1}
                    value={cartItem.quantity}
                    onChange={(value) => onQuantityChange(index, value)}
                    updateInProcess={isUpdating}
                    data-test-id="item-qty-change"
                  />
                )}
              </div>

              <p className={classes.price}>{`$${price}`}</p>
            </div>
          </>
        </ListItem>
      );
    });

  if (!cartItems.length) {
    return (
      <div className={classes.emptyCart}>
        <Stack
          my={3}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={cartImage}
            alt="Logo"
            style={{
              width: '75%',
            }}
            data-test-id="menu-header-image"
          />
          <Typography mt={2} color="text.secondary">
            Your cart is empty.
          </Typography>
          <Typography color="text.secondary">
            Add items to start a new order.
          </Typography>
        </Stack>
      </div>
    );
  }

  return (
    <div
      id="cart-form"
      data-test-id="regular-cart-form"
      className={!isDesktop ? classes.root : ''}
    >
      {cartItems.length && !!(!isMinOrderPrice && minOrderPrice) ? (
        <div className={classes.minimumOrder}>
          {`Minimum order is $${minOrderPrice}`}
        </div>
      ) : null}
      <List className={classes.listItemWrapper} disablePadding>
        {renderCartItems()}
      </List>
      <div className={classes.orderPriceDetails}>
        {feesArray.map((fee) => {
          if (
            (!isCheckout && fee.id === 'cartSummaryLines') ||
            (!isCheckout && fee.id === 'checkoutScreenTaxesValue') ||
            (!isCheckout && fee.adjustable) ||
            (fee.name === TOTAL_COST_NAME && fee.isCheckout !== isCheckout)
          ) {
            return null;
          }

          if (fee.adjustable) {
            const baseValue = fee?.base;
            if (!baseValue) return null;
            const feeValue = baseValue * (dynamicFeesValues[fee.id] / 100) || 0;
            const firstThreeTips = fee.adjust_percentages?.slice(0, 3);
            const lastThreeTips = fee.adjust_percentages?.slice(3, 6);

            return (
              <div
                className={classes.tips}
                key={fee.id}
                data-test-id="fee-element"
              >
                <div className={classes.tipParentBox}>
                  <span className={classes.priceTitle} data-test-id="fee-name">
                    {fee.name}
                  </span>
                  <span
                    className={`${classes.priceNumber} ${classes.tipPrice}`}
                    data-test-id="fee-value"
                  >
                    {`$${feeValue.toFixed(2)}`}
                  </span>
                </div>
                <Grid lg={12} sx={{ display: 'flex' }}>
                  {firstThreeTips?.map((tipItem) => {
                    const isTipActive = tipItem === dynamicFeesValues[fee.id];
                    return (
                      <Grid
                        key={`tip-item-${tipItem}`}
                        lg={6}
                        className={
                          isTipActive
                            ? classes.activeTipColor
                            : classes.gridColors
                        }
                        onClick={() => onFeeValueChange(fee.id, tipItem)}
                        data-test-id="tip-button"
                      >
                        <Box>{`${tipItem}%`}</Box>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid lg={12} sx={{ display: 'flex' }}>
                  {lastThreeTips?.map((tipItem) => {
                    const isTipActive = tipItem === dynamicFeesValues[fee.id];
                    return (
                      <Grid
                        key={`tip-item-${tipItem}`}
                        lg={6}
                        className={
                          isTipActive
                            ? classes.activeTipColor
                            : classes.gridColors
                        }
                        data-test-id="tip-button"
                        onClick={() => onFeeValueChange(fee.id, tipItem)}
                      >
                        <Box>{`${tipItem}%`}</Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            );
          }

          return (
            <div
              key={fee.id}
              className={[
                classes.orderDetailsItem,
                fee.name === TOTAL_COST_NAME ? classes.total : '',
              ].join(' ')}
              data-test-id="fee-element"
            >
              <p
                className={classes.priceTitle}
                data-test-id="fee-name"
              >{`${fee.name}`}</p>

              <p className={classes.priceNumber} data-test-id="fee-value">
                {`$${(fee.value || fee.amount)?.toFixed(2) || 0}`}
              </p>
            </div>
          );
        })}
      </div>

      {isShowGiftCodeInput && isCheckout && (
        <div className={classes.giftCode}>
          <TextField
            InputProps={{
              className: classes.couponField,
              'data-test-id': 'coupon-input',
            }}
            variant="outlined"
            value={couponString}
            onChange={(e) => setCouponString(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && applyCoupon()}
            fullWidth
            placeholder="Enter coupon code"
            data-test-id="200-off-monday"
          />

          <Button data-test-id="giftcode-submit" onClick={applyCoupon}>
            Apply
          </Button>
        </div>
      )}
      {showCheckoutButton && (
        <div
          className={
            !isCartModalOpen ? classes.checkout : classes.cartOpenCheckout
          }
        >
          <Button
            onClick={onCheckoutStartClick}
            fullWidth
            size="large"
            variant="contained"
            data-test-id="submit-btn"
            disabled={!isPurchaseEnabled || onCheckoutStart}
          >
            {!isCartModalOpen
              ? 'Go to Checkout'
              : `Checkout $${modalCartTotal}`}
            {!isCartModalOpen && (
              <span className={classes.checkoutArrowIcon}>
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
            )}
          </Button>
        </div>
      )}
    </div>
  );
}

CartFormComponent.propTypes = {
  isDesktop: PropTypes.bool,
  showCheckoutButton: PropTypes.bool,
  onCheckoutReady: PropTypes.func,
  isShowGiftCodeInput: PropTypes.bool,
};

CartFormComponent.defaultProps = {
  showCheckoutButton: true,
  isDesktop: false,
  onCheckoutReady: null,
  isShowGiftCodeInput: false,
};

export default CartFormComponent;
